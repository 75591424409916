const navMobile = document.querySelector('.nav-mobile')
const navBtn = document.querySelector('.hamburger')
const navLinks = document.querySelectorAll('.nav__link')
const itGeeksLogo = document.querySelector('.logo')
const footerYear = document.querySelector('.footer__year')

document.addEventListener('DOMContentLoaded', () => {
	navBtn.addEventListener('click', () => {
		navBtn.classList.toggle('is-active')
		navMobile.classList.toggle('nav-mobile--active')
	})
})

navLinks.forEach(item => {
    item.addEventListener('click', (event) => {
        // Jeśli element ma klasę 'translate-box', zatrzymaj domyślne zachowanie
        if (item.classList.contains('translate-box')) {
            event.preventDefault();
        } else {
            // W przeciwnym razie zamknij nawigację
            navMobile.classList.remove('nav-mobile--active');
            navBtn.classList.remove('is-active');
        }
    });
});

itGeeksLogo.addEventListener('click', () => {
	navBtn.classList.remove('is-active')
	navMobile.classList.remove('nav-mobile--active')
})

// GOOGLE TRANSLATE (przenoszenie tłumaczenia)

function moveTranslateBox() {
    const translateBox = document.querySelector('.translate-box'); // Pobiera element tłumaczenia
    const navDesktop = document.querySelector('.nav-desktop');    // Nawigacja desktopowa
    const navMobile = document.querySelector('.nav-mobile');      // Nawigacja mobilna

    if (window.innerWidth > 1100) {
        // Przenoszenie do nawigacji desktopowej
        if (!navDesktop.contains(translateBox)) {
            navDesktop.appendChild(translateBox);
        }
    } else {
        // Przenoszenie do nawigacji mobilnej
        if (!navMobile.contains(translateBox)) {
            navMobile.appendChild(translateBox);
        }
    }
}

// Nasłuchiwanie na zmianę rozmiaru okna
window.addEventListener('resize', moveTranslateBox);

// Pierwsze wywołanie, aby ustawić pozycję na starcie
moveTranslateBox();


document.addEventListener('DOMContentLoaded', function () {
	const nav = document.querySelector('.mobile-underline')
	const scrollDistance = 300

	function handleScroll() {
		if (window.scrollY > scrollDistance) {
			nav.classList.add('nav-scrolled')
		} else {
			nav.classList.remove('nav-scrolled')
		}
	}

	window.addEventListener('scroll', handleScroll)

	handleScroll()
})

// ACORDEON

document.addEventListener('DOMContentLoaded', function () {
	const accordionItems = document.querySelectorAll('.accordion-item')

	accordionItems.forEach(item => {
		const header = item.querySelector('.accordion-header')
		header.addEventListener('click', () => {
			const isActive = item.classList.contains('active')

			// Zamyka wszystkie inne sekcje
			accordionItems.forEach(otherItem => {
				otherItem.classList.remove('active')
			})

			// Przełącza aktywność tylko dla klikniętej sekcji
			if (!isActive) {
				item.classList.add('active')
			}
		})
	})
})

// USUNIĘCIE LINKU Z TŁUMACZENIA

// const interval = setInterval(function () {
// 	const element = document.querySelector('.eapps-widget .hQJLxj a')
// 	if (element) {
// 		element.style.display = 'none'
// 		clearInterval(interval) // Przestań sprawdzać, gdy element zostanie znaleziony
// 	}
// }, 10) // Sprawdza co 10 milisekund

// ACHIEVMENTS SECTION

document.addEventListener('DOMContentLoaded', function () {
	const counters = document.querySelectorAll('.achievment')
	const speed = 0.01

	const options = {
		root: null, // Obserwujemy względem całego viewportu
		threshold: 0.001, // Animacja uruchomi się, gdy 10% sekcji będzie widoczne
	}

	const callback = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				const counter = entry.target
				const target = +counter.getAttribute('data-target')
				let count = 0

				const updateCount = () => {
					count++
					if (count < target) {
						counter.textContent = count
						setTimeout(updateCount, speed)
					} else {
						counter.textContent = target + '+' // Dodajemy '+' na końcu liczby
					}
				}

				updateCount()
				observer.unobserve(counter) // Przestajemy obserwować po uruchomieniu animacji
			}
		})
	}

	const observer = new IntersectionObserver(callback, options)

	counters.forEach(counter => {
		observer.observe(counter)
	})
})

// PORTFOLIO SECTION

document.addEventListener('DOMContentLoaded', () => {
	const modal = document.querySelector('.modal');
	const modalContent = modal.querySelector('.modal-content');
	const modalImages = modal.querySelector('.modal-images');
	const closeModalBtn = modal.querySelector('.close');
	const prevBtn = modal.querySelector('.prev');
	const nextBtn = modal.querySelector('.next');
	const projects = document.querySelectorAll('.project');
	const filterButtons = document.querySelectorAll('.filter-btn');
	let currentProjectIndex = 0;

	const isMobile = () => window.innerWidth <= 1024;

	const showModal = index => {
		currentProjectIndex = index;
		const project = projects[index];
		const imgSrc = project.querySelector('img').src;
		modalImages.innerHTML = `<img src="${imgSrc}" alt="Project Image">`;
		modal.style.display = 'flex';
		document.body.style.overflow = 'hidden';
		projects.forEach(p => p.classList.remove('active')); // Usuwanie aktywnej klasy po włączeniu modala
	};

	const closeModal = () => {
		modal.style.display = 'none';
		document.body.style.overflow = 'auto';
	};

	const showNextProject = () => {
		if (currentProjectIndex < projects.length - 1) {
			showModal(currentProjectIndex + 1);
		} else {
			showModal(0);
		}
	};

	const showPrevProject = () => {
		if (currentProjectIndex > 0) {
			showModal(currentProjectIndex - 1);
		} else {
			showModal(projects.length - 1);
		}
	};

	projects.forEach((project, index) => {
		project.addEventListener('click', () => {
			if (isMobile()) {
				if (project.classList.contains('active')) {
					showModal(index);
				} else {
					projects.forEach(p => p.classList.remove('active'));
					project.classList.add('active');
				}
			} else {
				showModal(index); // Na komputerach modal otwiera się od razu
			}
		});
	});

	closeModalBtn.addEventListener('click', closeModal);
	prevBtn.addEventListener('click', showPrevProject);
	nextBtn.addEventListener('click', showNextProject);

	window.addEventListener('click', e => {
		if (e.target === modal) {
			closeModal();
		}
	});

	document.addEventListener('keydown', e => {
		if (modal.style.display === 'flex') {
			if (e.key === 'Escape') {
				closeModal();
			} else if (e.key === 'ArrowRight') {
				showNextProject();
			} else if (e.key === 'ArrowLeft') {
				showPrevProject();
			}
		}
	});

	filterButtons.forEach(button => {
		button.addEventListener('click', () => {
			const category = button.getAttribute('data-category');

			projects.forEach(project => {
				const projectCategories = project.getAttribute('data-category').split(',');

				if (projectCategories.includes(category) || category === 'all') {
					project.style.display = 'block';
				} else {
					project.style.display = 'none';
				}
			});
		});
	});
});

//PRZEKIEROWYWANIE NA PODSTRONĘ PO WYSŁANIU FORMULARZA

document.addEventListener('DOMContentLoaded', function () {
	const form = document.querySelector('form')
	const submitButton = document.querySelector('.contact__form-btn')

	form.addEventListener('submit', function (event) {
		event.preventDefault() // Zatrzymuje domyślne zachowanie formularza

		fetch(form.action, {
			method: form.method,
			body: new FormData(form),
			headers: {
				Accept: 'application/json',
			},
		})
			.then(response => {
				if (response.ok) {
					// Jeśli wysyłanie zakończyło się sukcesem
					window.location.href = 'https://it-geeks.pl/' // Tutaj podaj swój URL
				} else {
					alert('Coś poszło nie tak. Spróbuj ponownie.')
				}
			})
			.catch(error => {
				alert('Błąd: ' + error.message)
			})
	})
})

// USUNIĘCIE LINKU Z TŁUMACZENIA

// const interval = setInterval(function () {
// 	const element = document.querySelector('.eapps-widget .hQJLxj a')
// 	if (element) {
// 		element.style.display = 'none'
// 		clearInterval(interval) // Przestań sprawdzać, gdy element zostanie znaleziony
// 	}
// }, 10) // Sprawdza co 10 milisekund

// COOCKIE

document.addEventListener('DOMContentLoaded', function () {
	const cookieNotification = document.getElementById('cookie-notification')
	const acceptButton = document.getElementById('accept-cookie')

	const COOKIE_NAME = 'cookieConsent'
	const COOKIE_EXPIRATION_DAYS = 45

	function setCookie(name, value, days) {
		const d = new Date()
		d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
		const expires = 'expires=' + d.toUTCString()
		document.cookie = name + '=' + (value || '') + ';' + expires + ';path=/'
	}

	function getCookie(name) {
		const nameEQ = name + '='
		const ca = document.cookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') c = c.substring(1)
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
		}
		return null
	}

	function checkCookieConsent() {
		const consent = getCookie(COOKIE_NAME)
		if (consent) {
			cookieNotification.classList.add('hidden')
		} else {
			cookieNotification.classList.remove('hidden')
		}
	}

	acceptButton.addEventListener('click', function () {
		setCookie(COOKIE_NAME, 'true', COOKIE_EXPIRATION_DAYS)
		cookieNotification.classList.add('hidden')
	})

	checkCookieConsent()
})

//FOOTER

const handleCurrentYear = () => {
	const year = new Date().getFullYear()
	footerYear.innerText = year
}

handleCurrentYear()